import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
	FaChevronRight,
	FaFacebook,
	FaInfo,
	FaInstagram,
	FaTwitter,
	FaUpload,
} from 'react-icons/fa';
//import { currentEvent } from '../../utils/event';

function removeHttps(string: string): string {
	return string.replace('https://', '');
}

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					# TODO: use latest event!
					title
					social {
						twitter
						instagram
						facebook
						discord
						devpost
						hackerGuide
					}
				}
			}

			eventsData: allEventsYaml(sort: { order: ASC, fields: date }) {
				nodes {
					title
					startDateTime
					endDateTime
				}
			}
		}
	`);
	// TODO: better type
	// const event: any = currentEvent(
	// 	data.eventsData.nodes.filter((event) => event.startDateTime && event.endDateTime),
	// );
	const { social } = data.site.siteMetadata;
	return (
		<Page title="Live Home" live>
			<h1 className="text-center font-bold text-5xl pt-8 mb-16">
				{/* Welcome to {event ? event.title : 'HackCU'}! */}
				Welcome to HackCU 8
			</h1>
			<div className="flex flex-col md:flex-row mb-5">
				<div className="w-full md:w-2/3 px-2">
					<div className="p-3 text-xl rounded-md bg-secondary-500 space-y-8">
						<div>
							<h2 className="text-3xl font-bold mb-2">General Information</h2>
							<b>
								<FaChevronRight className="inline-block" /> Discord:{' '}
								<Link className="font-bold text-primary-500" outside to={social.discord}>
									{removeHttps(social.discord)}
								</Link>
							</b>
							<p className="mt-2">
								Join the HackCU Discord to talk with all the hackers attending our event!
							</p>
						</div>

						<div>
							<b>
								<FaUpload className="inline-block" /> Devpost:{' '}
								<Link className="font-bold text-primary-500" outside to={social.devpost}>
									{removeHttps(social.devpost)}
								</Link>
							</b>
							<p className="mt-2">
								Register for HackCU on Devpost, and submit your hack here by 12:00 pm
								<b>(submit early, you can edit your submission!)</b>
							</p>
						</div>

						<div>
							<b>
								<FaInfo className="inline-block" /> Hacker guide:{' '}
								{/* TODO: fix weird link randomly changing bug (netlify?) */}
								<Link className="font-bold text-primary-500" outside to={social.hackerGuide}>
									{removeHttps(social.hackerGuide)}
								</Link>
							</b>
							<p className="mt-2">
								Check out all the information that you need in this useful guide we have written
								down for you!
							</p>
						</div>
					</div>
				</div>

				<div className="w-full md:w-1/3 mt-2 md:mt-0 px-2">
					<div className="p-3 text-xl rounded-md bg-secondary-500 space-y-4">
						<h2 className="text-3xl font-bold">#HackCU</h2>
						<p className="mt-2">
							Get{' '}
							<Link
								className="font-bold text-primary-500"
								outside
								to="https://twitter.com/search?src=typd&q=%23lhd"
							>
								#HackCU
							</Link>{' '}
							trending!
						</p>
						<p className="text-3xl font-bold">
							<FaTwitter className="inline-block" />{' '}
							<Link className="font-bold text-primary-500" outside to={social.twitter}>
								@HackCU
							</Link>
						</p>
						<p className="text-3xl font-bold">
							<FaInstagram className="inline-block" />{' '}
							<Link className="font-bold text-primary-500" outside to={social.instagram}>
								@HackCU
							</Link>
						</p>
						<p className="text-3xl font-bold">
							<FaFacebook className="inline-block" />{' '}
							<Link className="font-bold text-primary-500" outside to={social.facebook}>
								/HackCU
							</Link>
						</p>
					</div>

					{/* <Card p={3} mt={2} fontSize={3}>
						<Text fontSize={4}>
							<FaWifi /> <Text as="b">Network: </Text> UCB Guest
						</Text>
						<Text mt={2}>
							Open your browser and go to{" "}
							<Link outside to="https://cuwireless.int/colorado.edu">
								cuwireless.int.colorado.edu
							</Link>{" "}
							to accept the usage agreement.
						</Text>
					</Card> */}
				</div>
			</div>
		</Page>
	);
};

export default IndexPage;
